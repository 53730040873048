import React, { useCallback, useMemo, useState } from 'react';
import NatureButton from '../shared/buttons/NatureButton';
import CheckInputBox from '../shared/form/CheckInputBox';
import MultiLineTextBox from '../shared/form/MultiLineTextBox';
import TextInput from '../shared/form/TextInput';

import { navigate } from "gatsby"

const MessageUs = () => {
  const neededChkValues = useMemo(
    () => ['Web Design', 'Web Development', 'Logo Design', 'Other'],
    []
  );

  const [frmState, setFrmState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    need: neededChkValues[0],
    message: '',
  });

  const handleUpdateFormValue = useCallback((e) => {
    if (e.target?.name) {
      const { name, value } = e.target;
      setFrmState((state) => ({ ...state, [name]: value }));
    }
  }, []);


  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }


  const handleSubmitClicked = async (e) => {
    e.preventDefault()

    try {
    await fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:   encode({ "form-name": "JSX Contact Form", ...frmState })
    }) 
    navigate('/nature/thank-you')
    } catch(e){
      alert("Something went wrong submitting the form")
    }
  }

  return (
    <div className='message-us'>
      <div className='message-us-title'>Message Us</div>
      <div className='message-us-form'>
        <form className='form-wrapper'  
        netlify-honeypot="bot-field" 
        name="JSX Contact Form" method="post" data-netlify="true">
        <input type="hidden" name="contact" value="JSX Contact Form" />
          <div className='form-inputline'>
            <TextInput
              label='First Name'
              defaultColor='#9E6CFF'
              activeColor='#9E6CFF'
              type='text'
              placeholder='John'
              className='form-text-input'
              name='firstName'
              value={frmState.firstName}
              onChange={handleUpdateFormValue}
            />
            <TextInput
              defaultColor='#9E6CFF'
              activeColor='#9E6CFF'
              label='Last Name'
              type='text'
              placeholder='Smith'
              className='form-text-input'
              name='lastName'
              value={frmState.lastName}
              onChange={handleUpdateFormValue}
            />
          </div>
          <div className='form-inputline'>
            <TextInput
              label='Mail'
              defaultColor='#9E6CFF'
              activeColor='#9E6CFF'
              type='email'
              name='email'
              value={frmState.email}
              onChange={handleUpdateFormValue}
              placeholder='johnsmith@gmail.com'
              className='form-text-input'
            />

          </div>
          <div className='form-checkbox-group'>
            <div className='form-checkbox-group-title'>What do you need?</div>
            <div className='form-inputline'>
              {neededChkValues.map((item) => (
                <CheckInputBox
                  key={item}
                  defaultColor='#9E6CFF'
                  activeColor='#E0D4FF'
                  label={item}
                  value={item}
                  name='need'
                  type='radio'
                  onChange={handleUpdateFormValue}
                  className='form-check-input'
                  checked={item === frmState.need}
                />
              ))}
            </div>
          </div>
          <div className='message-group'>
            <div className='message-group-title'>Message</div>
            <div className='message-group-frm form-inputline'>
              <MultiLineTextBox
                defaultColor='#9E6CFF'
                activeColor='#9E6CFF'
                placeholder='Write your message'
                name='message'
                value={frmState.message}
                onChange={handleUpdateFormValue}
              />
            </div>
            <div className='message-group-action'>
              <NatureButton
                title='Send Message'
                className='submit-btn'
                color='#9E6CFF'
                onClick={handleSubmitClicked}
                type='submit'
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MessageUs;

